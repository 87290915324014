// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Source+Sans+Pro:wght@900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  margin: 0;\n  text-decoration: none;\n}\n\nhtml,\nbody {\n  height: 100vh;\n  display: grid;\n  place-items: center;\n  background-color: black;\n}\n\n.page-wrapper {\n  display: grid;\n  place-items: center;\n  text-align: center;\n}\n\nh1 {\n  color: white;\n  font-family: \"Source Sans Pro\", sans-serif;\n  font-size: 5em;\n  letter-spacing: -0.04em;\n}\n\np {\n  color: rgb(98, 0, 255);\n  font-family: \"Comfortaa\", cursive;\n  font-size: 1.5em;\n  margin: 15px 0;\n}\n\n.divider {\n  color: white;\n}\n\n.icon {\n  color: white;\n  padding: 15px;\n  border-radius: 50%;\n}\n\n.icon:hover {\n  cursor: pointer;\n  background-color: rgb(22, 22, 22);\n}\n", "",{"version":3,"sources":["webpack://./src/styles/main.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,qBAAqB;AACvB;;AAEA;;EAEE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,0CAA0C;EAC1C,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,iCAAiC;EACjC,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iCAAiC;AACnC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Source+Sans+Pro:wght@900&display=swap\");\n\n* {\n  margin: 0;\n  text-decoration: none;\n}\n\nhtml,\nbody {\n  height: 100vh;\n  display: grid;\n  place-items: center;\n  background-color: black;\n}\n\n.page-wrapper {\n  display: grid;\n  place-items: center;\n  text-align: center;\n}\n\nh1 {\n  color: white;\n  font-family: \"Source Sans Pro\", sans-serif;\n  font-size: 5em;\n  letter-spacing: -0.04em;\n}\n\np {\n  color: rgb(98, 0, 255);\n  font-family: \"Comfortaa\", cursive;\n  font-size: 1.5em;\n  margin: 15px 0;\n}\n\n.divider {\n  color: white;\n}\n\n.icon {\n  color: white;\n  padding: 15px;\n  border-radius: 50%;\n}\n\n.icon:hover {\n  cursor: pointer;\n  background-color: rgb(22, 22, 22);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
